<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
          size="50"
        >
          <v-img src="4talents.png" max-height="30" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="display-1" v-text="profile.title" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "الرئيسية",
        to: "/",
      },
      {
        icon: "mdi-school",
        title: "المستخدمين",
        children: [
          // {
          //   icon: "mdi-account",
          //   title: "البيانات الشخصية",
          //   to: "pages/user",
          // },
          {
            icon: "mdi-account",
            title: "إضافة مستخدم",
            to: "add",
          },
          {
            icon: "mdi-account",
            title: "إضافة بيانات محاضر",
            to: "instructorData",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "المميزات",
        children: [
          {
            icon: "mdi-account",
            title: "المميزات",
            to: "features",
          },
          {
            icon: "mdi-account",
            title: "مميزات المنتجات",
            to: "Courses-features",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "البلاد",
        children: [
          {
            icon: "mdi-account",
            title: "البلاد",
            to: "countries",
          },
        ],
      },
      // {
      //   icon: "mdi-cog",
      //   title: "settings",
      //   to: "/settings",
      // },
      {
        icon: "mdi-school",
        title: "الإعلانات",
        children: [
          {
            icon: "mdi-cog",
            title: "الإعلانات",
            to: "advertisement",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "الشركاء",
        children: [
          {
            icon: "mdi-cog",
            title: "الشركاء",
            to: "partenars",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "الدورات",
        children: [
          {
            icon: "mdi-cog",
            title: "الدورات",
            to: "courses",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "المقاييس",
        children: [
          {
            icon: "mdi-cog",
            title: "المقاييس",
            to: "indicator",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "الجلسات",
        children: [
          {
            icon: "mdi-cog",
            title: "الجلسات",
            to: "sessions",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "الكوبونات",
        children: [
          {
            icon: "mdi-cog",
            title: "الكوبونات",
            to: "coupons",
          },
        ],
      },
      {
        icon: "mdi-school",
        title: "الإعدادات",
        children: [
          {
            icon: "mdi-cog",
            title: "الإعدادات",
            to: "setting",
          },
        ],
      },
      {
        icon: "mdi-pencil",
        title: "المقالات",
        children: [
          {
            icon: "mdi-account",
            title: "الوسوم",
            to: "tags",
          },
          {
            icon: "mdi-pencil",
            title: "المقالات",
            to: "posts",
          },
          // {
          //   icon: "mdi-plus",
          //   title: "إضافة مقال",
          //   to: "posts/add",
          // },
        ],
      },

      // {
      //   title: "notifications",
      //   icon: "mdi-bell",
      //   to: "/components/notifications",
      // },
      // {
      //   icon: "mdi-timeline",
      //   title: "timeline",
      //   to: "/timeline",
      // },
    ],
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
      ["Settings", "mdi-cog-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
  }),

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
